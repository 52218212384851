<template>
  <div>
    <form-wizard
      :color="is_loading.submit ? '#b0a8ff' : '#38B22D'"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="is_loading.submit ? 'Saving...' : 'Save'"
      class="mb-3"
      @on-complete="formSubmitted()"
    >
      <!-- employee_leave details tab -->
      <tab-content
        title="EmployeeLeave Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-form autocomplete="off" id="employee-leave-form">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Employee select"
                  label-for="employee-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Employee Title"
                    rules="required"
                  >
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      v-model="user_id"
                      :options="employeeListOptions"
                      class="w-100"
                      label="label"
                      :reduce="(val) => val.code"
                      placeholder="Select Employee.."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Session"
                  label-for="session"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Session"
                    rules="required"
                  >
                    <b-form-spinbutton
                      v-model="sessionFilter"
                      min="2020"
                      :max="new Date().getFullYear()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Leave Type" label-for="leave-type-select">
                  <validation-provider
                    #default="{ errors }"
                    name="Leave Type"
                    rules="required"
                  >
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      v-model="leave_type_id"
                      :options="leavesListOptions"
                      class="w-100"
                      label="label"
                      :reduce="(val) => val.code"
                      placeholder="Select Leave Type.."
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Balance" label-for="balance">
                  {{
                    employeeLeavesList[0] ? employeeLeavesList[0].granted == -1 ? "Unlimitted": employeeLeavesList[0].balance : 0
                  }}
                  Days
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Transaction Type"
                  label-for="transaction-type-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Transaction Type"
                    rules="required"
                  >
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      v-model="data_local.transaction_type"
                      :options="transactionsListOptions"
                      class="w-100"
                      label="label"
                      :reduce="(val) => val.code"
                      placeholder="Select Transaction Type.."
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="false">
                <b-form-group label="From Date" label-for="from-date">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="From Date"
                    rules="required"
                  > -->
                  <flat-pickr
                    :config="{ dateFormat: 'Y-m-d' }"
                    v-model="data_local.transactionFromDate"
                    class="form-control"
                    placeholder="Select Date.."
                    @input="calculateDays"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="false">
                <b-form-group
                  label="From Session"
                  label-for="transaction-type-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="From Session"
                    rules="required"
                  >
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      v-model="data_local.transactionFromSession"
                      :options="tranSessionListOptions"
                      class="w-100"
                      label="label"
                      :reduce="(val) => val.code"
                      placeholder="Select From Session.."
                      :clearable="false"
                      @input="calculateDays()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="false">
                <b-form-group
                  label="To Session"
                  label-for="transaction-type-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="To Session"
                    rules="required"
                  >
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      v-model="data_local.transactionToSession"
                      :options="tranSessionListOptions"
                      class="w-100"
                      label="label"
                      :reduce="(val) => val.code"
                      placeholder="Select To Session.."
                      :clearable="false"
                      @input="calculateDays()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="false">
                <b-form-group label="To Date" label-for="to-date">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="To Date"
                    rules="required"
                  > -->
                  <flat-pickr
                    :config="{ dateFormat: 'Y-m-d' }"
                    v-model="data_local.transactionToDate"
                    class="form-control"
                    placeholder="Select Date.."
                    @input="calculateDays()"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Reason" label-for="reason">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Reason"
                    rules="required"
                  > -->
                  <b-form-textarea trim v-model="data_local.reason" />

                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <b-col md="6" >
                <b-form-group label="Days" label-for="days">

                   <b-form-input
                      name="days"
                      v-model="data_local.days"
                      placeholder="days"
                      type="number"
                    />
                  <!-- {{ data_local.days }} -->
                </b-form-group>
              </b-col>
              

              <b-form-group
                v-for="(item, index) in customFields"
                :key="item.id"
                class="col-md-6"
              >
                <!-- #default="{ errors }" -->
                <validation-provider
                  :name="item.name"
                  rules=""
                  #default="validationContext"
                >
                  <b-form-group
                    v-if="item.type == 'number'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'text'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'url'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="url"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'rating'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <div class="d-flex align-items-center">
                      <star-rating
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :show-rating="false"
                        :star-size="30"
                      ></star-rating>
                      <span
                        v-if="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :class="`rating-btn btn ${
                          ratingdescription[
                            data_local.get_customfields_data['field_' + item.id]
                          ].class
                        }`"
                      >
                        {{
                          ratingdescription[
                            data_local.get_customfields_data["field_" + item.id]
                          ].text
                        }}</span
                      >
                      <input
                        type="hidden"
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                      />
                    </div>
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'password'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="password"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'textarea'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-textarea
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      trim
                      :placeholder="item.label"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.type == 'date'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <flat-pickr
                      :config="{ dateFormat: 'Y-m-d' }"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      class="form-control"
                      placeholder="Select Date.."
                    />
                  </b-form-group>

                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import store from "@/store";
import employeeLeaveStoreModule from "../employeeLeaveStoreModule";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      is_loading: {
        submit: false,
      },
      redirectEmployeeLeave: "",
      data_local: {
        transactionFromDate: new Date(),
        transactionToDate: new Date(),
        transactionFromSession: "session 1",
        transactionToSession: "session 2",
        days: "0",
        reason: "",
        get_customfields_data: [],
        user_id: "",
        leave_type_id: "",
        transaction_type:"granted",
        sessionFilter: new Date().getFullYear(),
      },
      user_id: "",
      leave_type_id: "",
      sessionFilter: new Date().getFullYear(),
      customFields: [],
      employeeListOptions: [],
      leavesListOptions: [],
      balance: 0,
      employeeLeavesList: [],
      transactionsListOptions: [
        // { label: "Availed", code: "availed" },
        { label: "Encashed", code: "encashed" },
        { label: "Granted", code: "granted" },
        // { label: "Lapsed", code: "lapsed" },
        // { label: "Opening Balance", code: "opening_balance" },
      ],
      tranSessionListOptions: [
        { label: "Session 1", code: "session 1" },
        { label: "Session 2", code: "session 2" },
      ],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-employee-leaves";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_APP_STORE_MODULE_NAME,
        employeeLeaveStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCustomFields();
    this.fetchEmployeeList();
    // this.fetchEmployeeLeaves();
  },
  watch: {
    user_id(val) {
      if (val && this.leave_type_id) {
        this.fetchEmployeeLeaves();
        this.data_local.user_id = val;
      }
    },
    sessionFilter(val) {
      if (val && this.leave_type_id) {
        this.fetchEmployeeLeaves();
        this.data_local.sessionFilter = val;
      }
    },
    leave_type_id(val) {
      if (val) {
        this.fetchEmployeeLeaves();
        this.data_local.leave_type_id = val;
      }
    },
  },

  methods: {
    calculateDays() {
      if (
        !this.data_local.transactionFromDate &&
        !this.data_local.transactionToDate
      ) {
        return true;
      }

      let fromDate = new Date(this.data_local.transactionFromDate);
      let toDate = new Date(this.data_local.transactionToDate);
      let days = toDate.getDate() - fromDate.getDate();
      if (
        this.data_local.transactionFromSession &&
        this.data_local.transactionToSession &&
        this.data_local.transactionFromSession ==
          this.data_local.transactionToSession
      ) {
        days = days - 0.5;
      }
      if (days) {
        this.data_local.days = ++days;
      }
    },
    fetchEmployeeLeaves() {
      if (this.user_id) {
        this.$store
          .dispatch("app-employee-leaves/fetchEmployeeLeaves", {
            hashid: this.user_id,
            year: this.sessionFilter,
            leave_type: this.leave_type_id,
          })
          .then((res) => {
            this.employeeLeavesList = res.data.data;
            if(this.employeeLeavesList[0]){
              if(this.employeeLeavesList[0]['granted'] == -1){
                this.data_local.days = -1;
              }else{
                this.data_local.days = 0;

              }
            }
          })
          .catch((err) => {
            // this.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: "fetch Employee List failed.",
            //       icon: "BellIcon",
            //       variant: "danger",
            //       text: err.toString(),
            //     },
            //   }
            // );
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          });
      }
    },
    fetchEmployeeList() {
      this.$store
        .dispatch("app/fetchEmployeeList")
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "fetch Employee List failed.",
          //       icon: "BellIcon",
          //       variant: "danger",
          //       text: err.toString(),
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    formSubmitted() {
      const self = this;
      this.is_loading.submit = true;
      this.data_local.user_id = this.user_id;
      this.data_local.sessionFilter = this.sessionFilter;
      this.data_local.leave_type_id = this.leave_type_id;
      this.$store
        .dispatch("app-employee-leaves/addEmployeeLeave", this.data_local)
        .then((res) => {
          if(res.status == '201'){
            // Add this employee_leave to NHR
            this.is_loading.submit = false;
            this.$router.push("/employee-leaves");
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Employee Leave Added",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Employee Leave Added',
                icon: 'EditIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          }
          
        })
        .catch((error) => {
          this.is_loading.submit = false;
          // this.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! Employee Leave Adding Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //       text: error,
          //     },
          //   });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Employee Leave Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-employee-leaves/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.employeeLeaveStatusOption = res.data.meta.status;
          self.leavesListOptions = res.data.leaves;
        })
        .catch((error) => {
          // self.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! Custom Fields Getting Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //     },
          //   });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Oops! Custom Fields Getting Failed`,
              icon:'BellIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";

.filepond--credits {
  display: none;
}
</style>

<style lang="scss" scoped>
.assign-interview-btn {
  position: absolute;
  right: 180px;
  bottom: 60px;
  border: solid 1px;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
